import PersonasApi from "@/api/personas.api";

const state = {
  garantias: null,
  permisos_submodulo: null,
  relaciones: null,
  detalles: null,
  acreditadosSinFondeo: null,
  creditosPersona: null
};
const getters = {
  getGarantias: state => state.garantias,
  getRelaciones: state => state.relaciones,
  getDetalles: state => state.detalles,
  getAcreditadosSinFondeo: state => state.acreditadosSinFondeo,
  getCreditosPersona: state => state.creditosPersona
};
const mutations = {
  SET_DETALLES(state, detalles) {
    state.detalles = detalles;
  },
  SET_GARANTIAS(state, garantias) {
    state.garantias = garantias;
  },
  SET_RELACIONES(state, relaciones) {
    state.relaciones = relaciones;
  },
  ADD_RELACION(state, relacion) {
    state.relaciones.push(relacion);
  },
  SET_ACREDITADOS_SIN_FONDEOS(state, acreditados) {
    state.acreditadosSinFondeo = acreditados;
  },
  SET_CREDITOS_PERSONA(state, creditos) {
    state.creditosPersona = creditos;
  }
};
const actions = {
  getDetallesPersona({ commit }, persona_id) {
    return PersonasApi.getDetallesPersona(
      persona_id,
      res => {
        commit("SET_DETALLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getAcreditadosActivosSinFondeo({ commit }) {
    return PersonasApi.getAcreditadosActivosSinFondeo(
      res => {
        commit("SET_ACREDITADOS_SIN_FONDEOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getGarantias({ commit }, persona_id) {
    return PersonasApi.getGarantias(
      persona_id,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRelaciones({ commit }, persona_id) {
    return PersonasApi.getRelaciones(
      persona_id,
      res => {
        commit("SET_RELACIONES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  relacionar({ commit }, data) {
    return PersonasApi.relacionar(
      data,
      res => {
        commit("ADD_RELACION", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCreditosConSaldoPersona({ commit }, persona_id) {
    return PersonasApi.getCreditosConSaldoPersona(
      persona_id,
      res => {
        commit("SET_CREDITOS_PERSONA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
