import LineasCreditoApi from "@/api/lineascredito.api";
import { base64ToBlob } from "@/vendors/helpers"

const initialState = {
  lineas_cliente: null,
  linea: null,
  resumen: null,
  lineas: null,
  lineas_filtradas: null,
  filtros: [],
  requisitos_producto: null,
  data_creacion: {
    garantias: null,
    personas: null,
    requisitos: null,
    contrato: null,
    fechas: null
  },
  requisitos: null,
  actividad: null,
  documentos: null,
  contrato: null,
  cuentas: null,
  disposiciones: null,
  garantias: null,
  garantiasArrendadas: null,
  archivos_garantia: null,
  bitacora: null,
  movimientos: null,
  cuenta: null,
  cuenta_financiada: null,
  cuentas_financiadas: null,
  estado_cuenta: false,
};

const state = initialState;

const getters = {
  getLineasCliente: state => state.lineas_cliente,
  getLinea: state => state.linea,
  getResumen: state => state.resumen,
  getLineas: state => state.lineas,
  getLineasFiltradas: state => state.lineas_filtradas,
  getFiltros: state => state.filtros,
  getRequisitosProducto: state => state.requisitos_producto,
  getDataCreacion: state => state.data_creacion,
  getActividad: state => state.actividad,
  getDocumentosLinea: state => state.documentos,
  getRequisitos: state => state.requisitos,
  getContrato: state => state.contrato,
  getCuentas: state => state.cuentas,
  getDisposiciones: state => state.disposiciones,
  getGarantias: state => state.garantias,
  getGarantiasArrendadas: state => state.garantiasArrendadas,
  getArchivosGarantia: state => state.archivos_garantia,
  getBitacora: state => state.bitacora,
  getMovimientos: state => state.movimientos,
  getCuenta: state => state.cuenta,
  getCuentaFinanciada: state => state.cuenta_financiada,
  getCuentasFinanciadas: state => state.cuentas_financiadas
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },
  SET_LINEAS_CLIENTE(state, lineas) {
    state.lineas_cliente = lineas;
  },
  SET_LINEA(state, linea) {
    state.linea = linea;
  },
  SET_RESUMEN(state, resumen) {
    state.resumen = resumen;
  },
  SET_LINEAS(state, lineas) {
    state.lineas = lineas;
  },
  SET_LINEAS_FILTRADAS(state, lineas) {
    state.lineas_filtradas = lineas;
  },
  SET_FILTROS(state, filtros) {
    state.filtros = filtros;
  },
  CLEAR_LINEAS_FILTRADAS(state) {
    state.lineas_filtradas = null;
    state.filtros = [];
  },
  SET_REQUISITOS_PRODUCTO(state, requisitos) {
    state.requisitos_producto = requisitos;
  },
  CLEAR_DATA_CREACION(state, data) {
    state.data_creacion = data;
  },
  SET_DATA_CREACION(state, data) {
    state.data_creacion[data.tipo] = data.data;
  },
  SET_REQUISITOS(state, requisitos) {
    state.requisitos = requisitos;
  },
  SET_ACTIVIDAD(state, actividad) {
    state.actividad = actividad;
  },
  SET_DOCUMENTOS(state, docs) {
    state.documentos = docs;
  },
  SET_CONTRATO(state, contrato) {
    state.contrato = contrato;
  },
  SET_CUENTAS(state, cuentas) {
    state.cuentas = cuentas;
  },
  SET_DISPOSICIONES(state, disp) {
    state.disposiciones = disp;
  },
  SET_GARANTIAS(state, garantias) {
    state.garantias = garantias;
  },
  SET_GARANTIAS_ARRENDADAS(state, garantias) {
    state.garantiasArrendadas = garantias;
  },
  ADD_GARANTIA(state, garantias) {
    if (state.gantias == null) {
      state.garantias = [garantias];
    } else {
      state.garantias.push(garantias);
    }
  },
  SET_ARCHIVOS_GARANTIA(state, archivos) {
    state.archivos_garantia = archivos;
  },
  SET_BITACORA(state, bitacora) {
    state.bitacora = bitacora;
  },
  SET_MOVIMIENTOS(state, movimientos) {
    state.movimientos = movimientos;
  },
  SET_CUENTA(state, cuenta) {
    state.cuenta = cuenta;
  },
  SET_CUENTA_FINANCIADA(state, cuenta) {
    state.cuenta_financiada = cuenta;
  },
  SET_CUENTAS_FINANCIADAS(state, cuentas) {
    state.cuentas_financiadas = cuentas;
  },
  SET_ESTADO_CUENTA(state, estado) {
    state.estado_cuenta = estado;
  },
};
const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  getLineasByPersona({ commit }, data) {
    return LineasCreditoApi.getLineasByPersona(
      data,
      res => {
        commit("SET_LINEAS_CLIENTE", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getLineaById({ commit }, linea_credito_id) {
    return LineasCreditoApi.getLineaById(
      linea_credito_id,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getLineasPasivasByPersona({ commit }, persona_id) {
    return LineasCreditoApi.getLineasPasivasByPersona(
      persona_id,
      res => {
        commit("SET_LINEAS_CLIENTE", res.data.lineas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  resumen({ commit }, tipo) {
    return LineasCreditoApi.resumen(
      tipo,
      res => {
        commit("SET_RESUMEN", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setFiltros({ commit }, filtros) {
    commit("SET_FILTROS", filtros);
    return true;
  },
  clearLineasFiltradas({ commit }) {
    commit("CLEAR_LINEAS_FILTRADAS");
    return true;
  },
  getLineas({ commit }, params) {
    let tipo = params.tipo;
    let pagina = params.pagina ? params.pagina : 1;
    let filtro = null;
    if (params.filtro) {
      filtro = params.filtro;
    }
    return LineasCreditoApi.getLineas(
      tipo,
      filtro,
      pagina,
      res => {
        if (filtro) {
          commit("SET_LINEAS_FILTRADAS", res.data);
        } else {
          commit("SET_LINEAS", res.data);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getLineasByStatus({ commit }, data) {
    return LineasCreditoApi.getLineasByStatus(
      data,
      res => {
        commit("SET_LINEAS_FILTRADAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRequisitosProducto({ commit }, producto_id) {
    return LineasCreditoApi.getRequisitosProducto(
      producto_id,
      res => {
        commit("SET_REQUISITOS_PRODUCTO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  clearDataCreacion({ commit }) {
    commit("CLEAR_DATA_CREACION", {
      garantias: null,
      personas: null,
      requisitos: null,
      contrato: null,
      fechas: null
    });
  },
  setDataCreacion({ commit }, data) {
    commit("SET_DATA_CREACION", data);
  },
  crear({ commit }, { pasiva, data }) {
    return LineasCreditoApi.crear(
      pasiva,
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  editar({ commit }, { pasiva, data }) {
    return LineasCreditoApi.editar(
      pasiva,
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setGarantias({ commit }, data) {
    return LineasCreditoApi.setGarantias(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setPersonas({ commit }, data) {
    return LineasCreditoApi.setPersonas(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  createRequisitos({ commit }, data) {
    return LineasCreditoApi.createRequisitos(
      data,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setRequisitos({ commit }, data) {
    return LineasCreditoApi.setRequisitos(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  resetContrato({ commit }) {
    commit("SET_CONTRATO", null);
  },
  createContrato({ commit }, data) {
    return LineasCreditoApi.createContrato(
      data,
      res => {
        commit("SET_CONTRATO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setContrato({ commit }, data) {
    return LineasCreditoApi.setContrato(
      data,
      res => {
        commit("SET_CONTRATO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setFechas({ commit }, data) {
    return LineasCreditoApi.setFechas(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setFechasIndividual({ commit }, data) {
    return LineasCreditoApi.setFechasIndividual(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getActividadReciente({ commit }, id) {
    return LineasCreditoApi.getActividadReciente(
      id,
      res => {
        commit("SET_ACTIVIDAD", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getDocumentos({ commit }, id) {
    return LineasCreditoApi.getDocumentos(
      id,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  ligarArchivos({ commit }, form) {
    return LineasCreditoApi.ligarArchivos(
      form,
      res => {
        commit("SET_DOCUMENTOS", res.data.archivos);
        return res.data;
      },
      () => false
    );
  },
  desligarDocumentos({ commit }, data) {
    return LineasCreditoApi.desligarArchivos(
      data,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getRequisitos({ commit }, linea_id) {
    return LineasCreditoApi.getRequisitos(
      linea_id,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  changeRequisitoStatus({ commit }, data) {
    return LineasCreditoApi.changeRequisitoStatus(
      data,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  ligarArchivosRequisito({ commit }, form) {
    return LineasCreditoApi.ligarArchivosRequisito(
      form,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  desligarArchivosRequisito({ commit }, data) {
    return LineasCreditoApi.desligarArchivosRequisito(
      data,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  agregarComentarioRequisito({ commit }, data) {
    return LineasCreditoApi.agregarComentarioRequisito(
      data,
      res => {
        commit("SET_REQUISITOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getContrato({ commit }, linea_id) {
    return LineasCreditoApi.getContrato(
      linea_id,
      res => {
        commit("SET_CONTRATO", res.data);
        return res.data;
      },
      () => false
    );
  },
  getCuentas({ commit }, linea_id) {
    return LineasCreditoApi.getCuentas(
      linea_id,
      res => {
        commit("SET_CUENTAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getDisposiciones({ commit }, data) {
    return LineasCreditoApi.getDisposiciones(
      data,
      res => {
        commit("SET_DISPOSICIONES", res.data);
        return res.data;
      },
      () => false
    );
  },
  getGarantias({ commit }, { linea_id, arrendables = false }) {
    return LineasCreditoApi.getGarantias(
      linea_id,
      arrendables,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getGarantiasArrendadas({ commit }, linea_id,) {
    return LineasCreditoApi.getGarantiasArrendadas(
      linea_id,
      res => {
        commit("SET_GARANTIAS_ARRENDADAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  archivosGarantiaNueva({ commit }, data) {
    commit("SET_ARCHIVOS_GARANTIA", data);
  },
  asignarGarantia({ commit }, data) {
    return LineasCreditoApi.asignarGarantia(
      data,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  asignarGarantiaExistente({ commit }, data) {
    return LineasCreditoApi.asignarGarantiaExistente(
      data,
      res => {
        commit("ADD_GARANTIA", res.data);
        return res.data;
      },
      () => false
    );
  },
  getBitacora({ commit }, linea_id) {
    return LineasCreditoApi.getBitacora(
      linea_id,
      res => {
        commit("SET_BITACORA", res.data);
        return res.data;
      },
      () => false
    );
  },
  getMovimientos({ commit }, data) {
    return LineasCreditoApi.getMovimientos(
      data,
      res => {
        commit("SET_MOVIMIENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  setMovimientos({ commit }, data) {
    commit("SET_MOVIMIENTOS", data);
    return data;
  },
  relacionar({ commit }, data) {
    return LineasCreditoApi.relacionar(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      () => false
    );
  },
  quitarRelacion({ commit }, data) {
    return LineasCreditoApi.quitarRelacion(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      () => false
    );
  },
  crearCuenta({ commit }, data) {
    return LineasCreditoApi.crearCuenta(
      data,
      res => {
        commit("SET_CUENTAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  setCuenta({ commit }, cuenta) {
    commit("SET_CUENTA", cuenta);
  },
  financiarCuenta({ commit }, data) {
    return LineasCreditoApi.financiarCuenta(
      data,
      res => {
        commit("SET_CUENTA_FINANCIADA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCuentasFinanciadas({ commit }, disposicion_id) {
    return LineasCreditoApi.getCuentasFinanciadas(
      disposicion_id,
      res => {
        commit("SET_CUENTAS_FINANCIADAS", res.data);
      },
      error => {
        return error;
      }
    );
  },
  cambiarStatus({ commit }, data) {
    return LineasCreditoApi.cambiarStatus(
      data,
      res => {
        commit("SET_LINEA", res.data);
        return res.data;
      },
      () => false
    );
  },
  printEstadoCuenta({ commit }, data) {
    return LineasCreditoApi.printEstadoCuenta(
      data,
      res => {
        let ticketWindow = null;
        if (res.data.pdf) {
          commit("SET_ESTADO_CUENTA", res.data.pdf);
          ticketWindow = window.open(
            "",
            "TicketWindow",
            "width=500,height=700"
          );
          // let obj = ticketWindow.document.createElement("object");
          // obj.style.width = "100%";
          // obj.style.height = "842pt";
          // obj.type = "application/pdf";
          // obj.data = "data:application/pdf;base64," + res.data.pdf;
          ticketWindow.document.title = "Estado de cuenta";
          const blob = base64ToBlob(res.data.pdf, 'application/pdf');
          const url = URL.createObjectURL(blob);
          ticketWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
          // ticketWindow.document.body.appendChild(obj);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
