import config from "@/../config";
import http from "@/api/http";
import { getLocalParam } from "@/vendors/helpers";

export default {
  getLineasByPersona(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/persona/${data.persona_id}?producto_financiero_id=${data.producto_financiero_id}&para_disponer=true`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getLineaById(linea_credito_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_credito_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getLineasPasivasByPersona(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/${data}/lineas_de_credito_pasivas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  resumen(tipo, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/resumen-status/${tipo}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getLineas(tipo, filtros, pagina, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/lineas_credito/index/${tipo}`, {
        params: {
          filtros: JSON.stringify(filtros),
          por_pagina: getLocalParam("paginador") || 20,
          pagina: pagina
        }
      })
      .then(onSuccess)
      .catch(onError);
  },
  getLineasByStatus(data, onSuccess, onError) {
    if (data.tipo == 1) {
      return http
        .get(
          `${config.host}/${config.version}/cartera_pasiva/lineas_credito/${data.status}`,
          {
            params: {
              por_pagina: getLocalParam("paginador") || 20,
              pagina: data.pagina
            }
          }
        )
        .then(onSuccess)
        .catch(onError);
    } else {
      return http
        .get(`${config.host}/${config.version}/lineas_credito/${data.status}`, {
          params: {
            por_pagina: getLocalParam("paginador") || 20,
            pagina: data.pagina
          }
        })
        .then(onSuccess)
        .catch(onError);
    }
  },
  getRequisitosProducto(producto_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/plantilla_productos/producto_financiero/${producto_id}/requisitos`
      )
      .then(onSuccess)
      .catch(onError);
  },
  crear(pasiva, data, onSuccess, onError) {
    const route = pasiva ? 'cartera_pasiva/lineas_credito' : 'lineas_credito';
    return http
      .post(`${config.host}/${config.version}/${route}`, data)
      .then(onSuccess)
      .catch(onError);
  },
  editar(pasiva, data, onSuccess, onError) {
    const route = pasiva ? 'cartera_pasiva/lineas_credito' : 'lineas_credito';
    return http
      .patch(
        `${config.host}/${config.version}/${route}/${data.linea_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  setGarantias(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_credito_id}/garantias`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  setPersonas(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_credito_id}/personas`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  createRequisitos(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/requisito/multiple`, data)
      .then(onSuccess)
      .catch(onError);
  },
  setRequisitos(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_credito_id}/requisitos`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  createContrato(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/contratos`, data)
      .then(onSuccess)
      .catch(onError);
  },
  setContrato(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/contratos/${data.contrato_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  setFechas(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_credito_id}/actualizar_fechas`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  /**
   * Al editar una línea de crédito, en la sección extras, guarda las fechas sin necesidad de guardar otros aspectos de la línea
   */
  setFechasIndividual(data, onSuccess, onError) {
    const url = data.pasiva
      ? `${config.host}/${config.version}/cartera_pasiva/lineas_credito/${data.linea_credito_id}/fechas`
      : `${config.host}/${config.version}/lineas_credito/${data.linea_credito_id}/fechas`;

    return http
      .patch(url, data)
      .then(onSuccess)
      .catch(onError);
  },
  getActividadReciente(id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${id}/actividad_reciente`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDocumentos(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/lineas_credito/${id}/archivos`)
      .then(onSuccess)
      .catch(onError);
  },
  ligarArchivos(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/lineas_credito/${data.id}/ligar_docs`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  desligarArchivos(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/desligar_docs/${data.file_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getRequisitos(linea_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_id}/requisitos`
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeRequisitoStatus(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/requisitos/${data.id}/status`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  ligarArchivosRequisito(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/requisitos/${data.id}/files`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  desligarArchivosRequisito(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/lineas_credito/requisitos/${data.id}/files/${data.file_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  agregarComentarioRequisito(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/lineas_credito/requisitos/agregar_comentario/${data.requisito_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getContrato(linea_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/contratos/linea/${linea_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getCuentas(linea_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_id}/cuentas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDisposiciones(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/disposiciones`,
        {
          params: {
            por_pagina: getLocalParam("paginador") || 20,
            pagina: data.pagina
          }
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  getGarantias(linea_id, arrendables, onSuccess, onError) {
    const tipo = arrendables ? 'garantias/arrendables' : 'garantias'
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_id}/${tipo}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getGarantiasArrendadas(linea_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_id}/garantias/arrendadas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarGarantia(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/asignar_garantia`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarGarantiaExistente(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/garantias/${data.garantia_id}/lineas_credito`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getBitacora(linea_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${linea_id}/bitacora`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getMovimientos(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/operaciones_diarias`,
        {
          params: {
            por_pagina: getLocalParam("paginador") || 20,
            pagina: data.pagina
          }
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  relacionar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/relacion/${data.relacion_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  quitarRelacion(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/relacion/${data.relacion_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  crearCuenta(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/cuentas/crear`, data)
      .then(onSuccess)
      .catch(onError);
  },
  getCuentasFinanciadas(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/cuenta_financiada/disposicion/${disposicion_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  financiarCuenta(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/cuenta_financiada/${data.cuenta_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  cambiarStatus(data, onSuccess, onError) {
    const { motivo, fecha_autorizado, grupo_interno_id } = data;

    if (data.tipo == true) {
      return http
        .patch(
          `${config.host}/${config.version}/cartera_pasiva/lineas_credito/${data.linea_id}/status/${data.status_nombre}`,
          {
            motivo,
            fecha_autorizado,
            grupo_interno_id
          }
        )
        .then(onSuccess)
        .catch(onError);
    }
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/status/${data.status_nombre}`,
        {
          motivo,
          fecha_autorizado,
          grupo_interno_id
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  printEstadoCuenta(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/print_estado_cuenta?fecha_inicial=${data.fecha_inicial}&fecha_final=${data.fecha_final}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  reestructurar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/reestructurar-lineas`,
        { lineas: data.lineas}
      )
      .then(onSuccess)
      .catch(onError);
  },
  renovar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/renovar-lineas`,
        { lineas: data.lineas}
      )
      .then(onSuccess)
      .catch(onError);
  },
  crearConfiguracionCreditoGrupal(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/lineas_credito/${data.linea.id}/configuracion_grupo_credito`,
        data 
      )
      .then(onSuccess)
      .catch(onError);
  },
  entregarDisposicionesCreditoGrupal(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/lineas_credito/${data.linea_id}/entregar_disposiciones_grupo_credito`
      )
      .then(onSuccess)
      .catch(onError);
  }
};
