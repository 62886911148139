<template>
  <ValidationProvider
    :rules="rules == 'none' ? '' : rules"
    v-slot="{ errors, valid }"
  >
    <b-field
      :type="{
        'is-danger': errors[0],
        'is-success': valid,
        'is-pasivo': pasivo == true
      }"
      :label="label"
      :message="errors"
      :class="fieldClass"
    >
      <b-autocomplete
        v-model="innerValue"
        :placeholder="placeholder"
        :keep-first="true"
        :field="show"
        :open-on-focus="true"
        :data="dataFiltered"
        @select="emitSelect"
        @typing="emitTyping"
        :icon-right="icon ? icon : ''"
        :disabled="disabled"
        :clearable="clearable"
        :clear-on-select="clearOnSelect"
      >
        <template slot-scope="props">
          <slot name="custom" v-bind:option="props">
            {{ props.option[show] }}
          </slot>
        </template>
        <template slot="empty">No hay resultados</template>
      </b-autocomplete>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "validAutoComplete",
  props: {
    rules: String,
    label: String,
    placeholder: String,
    expanded: {
      type: Boolean,
      default: false
    },
    data: Array,
    show: String,
    value: null,
    fieldClass: String,
    pasivo: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    custom: Boolean,
    icon: String
  },
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    dataFiltered() {
      if (this.data) {
        return this.data.filter(
          option =>
            this.resolve(this.show, option)
              ?.toString()
              .toLowerCase()
              .indexOf(this.innerValue?.toString().toLowerCase()) >= 0
        );
      }
      return [];
    }
  },
  methods: {
    emitSelect(val) {
      this.$emit("select", val);
    },
    emitTyping(val) {
      this.$emit("typing", val);
    },
    resolve(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
