import config from "@/../config";
import http from "@/api/http";

export default {
  getMe(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/me`)
      .then(onSuccess)
      .catch(onError);
  },
  getSofom(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/sofom`)
      .then(onSuccess)
      .catch(onError);
  },
  getSysAdmins(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/sofom/sys_admins`)
      .then(onSuccess)
      .catch(onError);
  },
  getAyudas(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/ayuda`)
      .then(onSuccess)
      .catch(onError);
  },
  toggleAyuda(ayuda_id, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/ayuda/${ayuda_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  login(data, onSuccess, onError) {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    http
      .post(
        `${config.host}/authorize?response_type=token&client_id=${
          config.client_id
        }&redirect_uri=${encodeURIComponent(config.client)}%2F&scope=${
          config.scope
        }`,
        formData
      )
      .then(onSuccess)
      .catch(onError);
  },
  logOut(data, onSuccess, onError) {
    return http
      .delete(`${config.host}/${config.version}/login`)
      .then(onSuccess)
      .catch(onError);
  },
  buscar(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/search?search=${data.val}&filtros=${data.filtros}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  cambiarSucursal(sucursal_id, onSuccess, onError) {
    return http
      .patch(`${config.host}/${config.version}/sucursal/${sucursal_id}`)
      .then(onSuccess)
      .catch(onError);
  }
};
