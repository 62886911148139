import FilesApi from "@/api/files.api";

const state = {
  status: false,
  archivos: null,
  parent: null
};
const getters = {
  getStatus: state => state.status,
  getArchivos: state => state.archivos,
  getParent: state => state.parent
};
const mutations = {
  CHANGE_STATUS(state, status) {
    state.status = status;
  },
  SET_ARCHIVOS(state, archivos) {
    state.archivos = archivos;
  },
  ADD_ARCHIVO(state, data) {
    state.archivos[data.carpeta].unshift(data.archivo);
  },
  REPLACE_ARCHIVO(state, archivo) {
    let file = state.archivos[archivo.carpeta].find(
      file => file.id == archivo.id
    );
    file.descripcion = archivo.descripcion;
    file.fecha_expiracion = archivo.fecha_expiracion;
  },
  SET_PARENT(state, data) {
    state.parent = data;
  }
};
const actions = {
  subirArchivos({ commit }, data) {
    return FilesApi.subirArchivos(
      data,
      res => {
        res.data.forEach(file => {
          delete file.persona;
          let fileToAdd = {
            carpeta: data.get("carpeta"),
            archivo: file
          };
          commit("ADD_ARCHIVO", fileToAdd);
        });
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getArchivos({ commit }, persona_id) {
    return FilesApi.getArchivos(
      persona_id,
      res => {
        commit("SET_ARCHIVOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  toggleCapvitalFiles({ commit }, data) {
    if (data === false) {
      commit("CHANGE_STATUS", data);
      commit("SET_PARENT", null);
      return;
    }
    commit("CHANGE_STATUS", data.status);
    delete data.status;
    commit("SET_PARENT", data);
  },
  guardarDatos({ commit }, data) {
    return FilesApi.guardarDatos(
      data,
      res => {
        res.data.forEach(file => {
          file.carpeta = data[0].carpeta;
          commit("REPLACE_ARCHIVO", file);
        });
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
