import config from "@/../config";
import http from "@/api/http";

export default {
  getArchivos(persona_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/files/persona/${persona_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  subirArchivos(files, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/archivos`, files)
      .then(onSuccess)
      .catch(onError);
  },
  guardarDatos(files, onSuccess, onError) {
    return http
      .patch(`${config.host}/${config.version}/files/cargar_datos`, files)
      .then(onSuccess)
      .catch(onError);
  }
};
