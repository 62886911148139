<template>
  <div class="has-background-white p-3 has-border-radius animated fadeIn">
    <div class="columns">
      <aside
        class="column is-3 has-background-white-ter has-border-radius"
        v-if="
          viewportSize > 960 || (viewportSize <= 960 && !mobileFolderSetted)
        "
      >
        <div class="sticky-sidebar">
          <ValidAutoComplete
            rules="required"
            label="Mostrando archivos de"
            placeholder="Buscar..."
            v-model="cliente"
            :data="getClientesConLineasCredito"
            show="alias"
            class="text-left"
            @typing="buscarClientes"
            @select="cargarArchivos"
            :expanded="true"
          />
          <div
            class="columns mt-3 is-multiline mb-4 min-height-20rem flex-col"
            v-if="getArchivos !== null"
          >
            <div class="column is-12 py-1">
              <div
                class="folder-list flex items-center p-2 has-border-radius"
                :class="{
                  'has-background-grey-lighter': carpeta_real_name == carpeta
                }"
                v-for="(carpeta, index) in Object.keys(getArchivos)"
                :key="index"
                @click="setCarpeta(carpeta)"
              >
                <b-icon icon="folder" class="ml-1" />
                <span class="has-text-grey-darker ml-3 capitalize text-left">{{
                  carpeta.split("en")[1]
                }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="columns hidden md:flex">
            <div
              class="column is-10 is-offset-1 has-background-grey-lighter has-border-radius"
            >
              <b-image src="/images/files.png" alt />
              <p class="font-bold mt-1">Obtén más espacio</p>
              <p class="text-sm">mini lorem ipsum sim amet</p>
              <b-button type="is-primary" expanded size="is-small" class="mt-8"
                >Comprar espacio</b-button
              >
            </div>
          </div>
          <p class="text-left text-sm has-text-danger ml-2">
            1.9 de 2GB usados
          </p> -->
        </div>
        <div class="row" v-if="carpeta">
          <valid-upload
            :expanded="true"
            rules="required"
            @input="subirArchivos"
          />
        </div>
      </aside>
      <div
        class="column is-9 text-left mb-4"
        v-if="viewportSize > 960 || (viewportSize <= 960 && mobileFolderSetted)"
      >
        <div v-if="carpeta == null" class="h-full">
          <p class="font-bold text-2xl">Mis documentos</p>
          <div class="h-90p flex flex-col items-center justify-center">
            <b-image
              class="w-1/2 m-auto"
              :src="
                getMe.genero
                  ? getMe.genero.id == 1
                    ? '/images/files_man.svg'
                    : '/images/files_woman.svg'
                  : '/images/files_man.svg'
              "
              alt="person holding files"
            />
            <p class="text-center mt-4 font-bold text-3xl">
              Bienvenido a Capvital Files
            </p>
            <p class="text-center">Empieza seleccionando una carpeta</p>
          </div>
        </div>
        <div v-else class="px-2">
          <div class="columns sticky-topbar">
            <div class="column is-8">
              <b-button
                v-if="viewportSize <= 960"
                @click="cleanMobileFolder"
                icon-right="arrow-left"
              />
              <p class="font-bold text-2xl capitalize">{{ carpeta }}</p>
              <p>{{ archivos.length }} documentos</p>
            </div>
            <div class="column mt-2">
              <b-field>
                <b-input
                  class="searchable"
                  placeholder="Buscar..."
                  type="search"
                  v-model="search"
                  icon-pack="fas"
                  icon="search"
                  @input="buscarArchivos"
                ></b-input>
              </b-field>
            </div>
          </div>
          <!-- <div class="columns is-multiline">
            <div class="column is-12">
              <valid-upload
                :expanded="true"
                rules="required"
                @input="subirArchivos"
              />
            </div>
          </div> -->
          <div class="columns relative">
            <transition-group class="w-full" name="fadeInOut" mode="out-in">
              <div
                class="column is-12 pb-0 archivos-list"
                v-for="doc in archivos"
                :key="doc.id"
              >
                <documento-card
                  :doc="doc"
                  :modal="true"
                  @checked="checkGlobal"
                  :check-active="
                    files_checked.find(file => file.id == doc.id) ? true : false
                  "
                />
              </div>             
            </transition-group>
          </div>
          <div v-if="archivos.length == 0" class="column is-12">
                <div class="h-65vh">
                  <empty-state
                    type="svg"
                    :vertical="true"
                    imagen="/images/empty_state_files.svg"
                    imgclass="w-2/5 mb-4"
                  />
                </div>
              </div>
        </div>
      </div>
    </div>
    <div class="sticky-button" v-if="files_checked.length > 0">
      <transition>
        <b-button
          type="is-primary"
          icon-right="arrow-right"
          @click="ligarArchivos"
        >
          Ligar {{ files_checked.length }}
          {{ files_checked.length == 1 ? "documento" : "documentos" }}
        </b-button>
      </transition>
    </div>
    <b-modal
      v-if="datos_extras"
      :active.sync="datos_extras"
      :can-cancel="false"
    >
      <datos-extras :files="files_to_update" :carpeta="carpeta_real_name" />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ValidUpload from "@/components/form/ValidUpload";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { debounce } from "@/vendors/helpers";
import DocumentoCard from "@/components/Files/DocumentoCard";
import DatosExtras from "@/components/Files/DatosExtras";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "CapvitalFiles",
  data() {
    return {
      carpeta: null,
      carpeta_real_name: null,
      archivos: null,
      search: "",
      files_checked: [],
      datos_extras: false,
      files_to_update: [],
      cliente: null,
      mobileFolderSetted: false
    };
  },
  components: {
    ValidUpload,
    DocumentoCard,
    DatosExtras,
    ValidAutoComplete,
    EmptyState
  },
  computed: {
    ...mapGetters(["getMe"]),
    ...mapGetters("files", ["getArchivos", "getParent"]),
    ...mapGetters("catalogos", ["getClientesConLineasCredito"]),
    viewportSize() {
      return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
    }
  },
  methods: {
    cleanMobileFolder() {
      this.mobileFolderSetted = false;
    },
    /*
      Indica la carpeta activa
      @carpeta (String): Carpeta a poner como activa
    */
    setCarpeta(carpeta) {
      this.archivos = this.getArchivos[carpeta];
      this.carpeta_real_name = carpeta;
      carpeta = carpeta.split("en")[1];
      this.carpeta = carpeta;
      this.mobileFolderSetted = true;
    },
    /*
      Sube archivos a la API
      @files (Array): Archivos a subir 
    */
    subirArchivos(files) {
      let formData = new FormData();
      files.forEach((file, index) => {
        formData.append("files[" + index + "]", file);
      });
      formData.append("carpeta", this.carpeta_real_name);
      this.$store.dispatch("files/subirArchivos", formData).then(files => {
        this.files_to_update = files;
        this.datos_extras = true;
        this.files_checked.push(...files);
      });
    },
    //Filtro para buscar archivos por nombre en la carpeta indicada
    buscarArchivos() {
      this.archivos = this.getArchivos[this.carpeta_real_name].filter(
        option =>
          option.nombre
            .toString()
            .toLowerCase()
            .indexOf(this.search.toString().toLowerCase()) >= 0
      );
    },
    /*
      Comprueba si los archivos seleccionado no estan seleccinoados anteriormente, es la función del checkbox
      @val ({file: this.doc,status: this.checkbox}): el status del archivo (seleccionado o no) y el documento que se checkeo
    */
    checkGlobal(val) {
      let repetido = this.files_checked.filter(file => file.id == val.file.id);
      if (repetido.length == 0) {
        this.files_checked.push(val.file);
      } else if (val.status == false) {
        let index = this.files_checked.findIndex(
          file => file.id == val.file.id
        );
        this.files_checked.splice(index, 1);
      }
    },
    //Liga archivos dependiendo de su parent al modelo correspondiente
    ligarArchivos() {
      let files_id = this.files_checked.map(file => file.id);
      let data = {
        archivos: files_id,
        id: this.getParent.id
      };
      switch (this.getParent.type) {
        case "disposicion":
          this.$store.dispatch("disposicion/ligarArchivos", data).then(() => {
            this.$store.dispatch("files/toggleCapvitalFiles", false);
          });
          break;
        case "pago":
          this.$store.dispatch("pagos/ligarArchivos", data).then(() => {
            this.$store.dispatch("files/toggleCapvitalFiles", false);
          });
          break;
        case "requisitos_supervision":
          this.$store.dispatch("supervision/ligarArchivos", data).then(() => {
            this.$store.dispatch("files/toggleCapvitalFiles", false);
          });
          break;
        case "garantias_disposicion":
          this.$store
            .dispatch("disposicion/archivosGarantiaNueva", this.files_checked)
            .then(() => {
              this.$store.dispatch("files/toggleCapvitalFiles", false);
            });
          break;
        case "garantias_linea":
          this.$store
            .dispatch("lineascredito/archivosGarantiaNueva", this.files_checked)
            .then(() => {
              this.$store.dispatch("files/toggleCapvitalFiles", false);
            });
          break;
        case "linea":
          this.$store.dispatch("lineascredito/ligarArchivos", data).then(() => {
            this.$store.dispatch("files/toggleCapvitalFiles", false);
          });
          break;
        case "requisitos_linea":
          this.$store
            .dispatch("lineascredito/ligarArchivosRequisito", data)
            .then(() => {
              this.$store.dispatch("files/toggleCapvitalFiles", false);
            });
          break;
      }
    },
    cargarArchivos(cliente) {
      this.$store.dispatch("files/getArchivos", cliente.id);
    },
    buscarClientes: debounce(function(nombre) {
      if (!nombre.length) {
        return;
      }
      this.$store.dispatch("catalogos/buscarClientesLineasCredito", nombre);
    }, 400)
  },
  mounted() {
    if (this.getArchivos == null && this.getParent != null) {
      this.$store.dispatch("files/getArchivos", this.getParent.persona.id).then(() => {
        switch (this.getParent.type) {
            case "disposicion":
              this.setCarpeta('en simulacion');
              break;
            case "pago":
              this.setCarpeta('en pagos');
              break;
            case "requisitos_supervision":
              this.setCarpeta('en requisitos de linea');
              break;
            case "garantias_disposicion":
              this.setCarpeta('en garantias');
              break;
            case "garantias_linea":
              this.setCarpeta('en garantias');
              break;
            case "linea":
              this.setCarpeta('en lineas de credito');
              break;
            case "requisitos_linea":
              this.setCarpeta('en requisitos de linea');
              break;
          }
      });
    }
    this.cliente = this.getParent.persona.nombre_completo;
  },
};
</script>
<style lang="scss">
.folder-list {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--white);
  }
}
.min-height-20rem {
  min-height: 20rem;
}
.searchable > input {
  border: 1px solid var(--grey-light);
  background-color: var(--white) !important;
  border-radius: 999px !important;
}
.fadeInOut-move {
  transition: transform 1s;
}
.fadeInOut-leave-active {
  position: absolute;
  opacity: 0;
}
.archivos-list {
  transition: all 1s;
}
.sticky-sidebar {
  position: sticky;
  top: 1rem;
}
.sticky-topbar {
  position: sticky;
  top: 0;
  margin-top: 0.75rem;
  z-index: 2;
  background-color: var(--white);
}
.sticky-button {
  position: sticky;
  bottom: 1.5rem;
  right: 1.5rem;
  text-align: right;
  display: inline-block;
  left: 100%;
}
</style>
