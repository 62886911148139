import DisposicionApi from "@/api/disposicion.api";
import PagosApi from "@/api/pagos.api";
import { base64ToBlob } from "@/vendors/helpers"

const initialState = {
  disposicion: null,
  disposicion_detalle: null,
  disposiciones: null,
  disposiciones_filtradas: null,
  tabla_amortizacion: null,
  filtros: null,
  toggle_change_status: false,
  procesar_operaciones: false,
  success: false,
  documentos: null,
  bitacora: null,
  operaciones_diarias: null,
  credito: null,
  pagos: null,
  supervisiones: null,
  garantias: null,
  archivos_garantia: null,
  pasivas: null,
  activas: null,
  fuentes_fondeo: null,
  castigo: [],
  estado_cuenta: false,
  tabla_amortizaciones: false,
  refinanciamiento_vencido: false,
  cuenta_prima: null,
  cuentas: null,
  cuenta: null,
  cuenta_financiada: null,
  cuentas_financiadas: null
};

const state = initialState;

const getters = {
  getDisposicion: state => state.disposicion,
  getDisposicionDetalle: state => state.disposicion_detalle,
  getDisposiciones: state => state.disposiciones,
  getDisposicionesFiltradas: state => state.disposiciones_filtradas,
  getTablaAmortizacion: state => state.tabla_amortizacion,
  getFiltros: state => state.filtros,
  getChangeStatus: state => state.toggle_change_status,
  getProcesarOperaciones: state => state.procesar_operaciones,
  getSuccess: state => state.success,
  getDocumentos: state => state.documentos,
  getBitacora: state => state.bitacora,
  getOperacionesDiarias: state => state.operaciones_diarias,
  getCredito: state => state.credito,
  getPagos: state => state.pagos,
  getSupervisiones: state => state.supervisiones,
  getGarantias: state => state.garantias,
  getArchivosGarantia: state => state.archivos_garantia,
  getPasivas: state => state.pasivas,
  getActivas: state => state.activas,
  getFuentesFondeo: state => state.fuentes_fondeo,
  getCastigo: state => state.castigo,
  getRefinanciamientoVencido: state => state.refinanciamiento_vencido,
  generarCuentaPrima: state => state.cuenta_prima,
  getCuentas: state => state.cuentas,
  getCuenta: state => state.cuenta,
  getCuentaFinanciada: state => state.cuenta_financiada,
  getCuentasFinanciadas: state => state.cuentas_financiadas
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },
  SET_DISPOSICION(state, disposicion) {
    state.disposicion = disposicion;
  },
  SET_DISPOSICION_DETALLE(state, disposicion) {
    state.disposicion_detalle = disposicion;
  },
  SET_DISPOSICIONES(state, disposiciones) {
    state.disposiciones = disposiciones;
  },
  SET_DISPOSICIONES_FILTRADAS(state, disposiciones) {
    state.disposiciones_filtradas = disposiciones;
  },
  SET_TABLA_AMORTIZACION(state, tabla) {
    state.tabla_amortizacion = tabla;
  },
  CLEAR_DISPOSICIONES_FILTRADAS(state) {
    state.disposiciones_filtradas = null;
  },
  SET_FILTROS(state, filtros) {
    state.filtros = filtros;
  },
  CHANGE_STATUS(state, status) {
    state.toggle_change_status = status;
  },
  SET_SUCCESS(state, status) {
    state.success = status;
  },
  CHANGE_PROCESAR_OPERACIONES(state, operacion_bool) {
    state.procesar_operaciones = operacion_bool;
  },
  SET_DOCUMENTOS(state, docs) {
    state.documentos = docs;
  },
  SET_BITACORA(state, bitacora) {
    state.bitacora = bitacora;
  },
  SET_OPERACIONES_DIARIAS(state, operaciones) {
    state.operaciones_diarias = operaciones;
  },
  SET_CREDITO(state, credito) {
    state.credito = credito;
  },
  SET_PAGOS(state, pagos) {
    state.pagos = pagos;
  },
  SET_SUPERVISIONES(state, supervisiones) {
    state.supervisiones = supervisiones;
  },
  SET_SUPERVISION_REAGENDADA(state, data) {
    if (
      state.supervisiones != null &&
      state.supervisiones.hasOwnProperty("sin_supervisar")
    ) {
      let index = state.supervisiones.sin_supervisar.findIndex(
        x => x.id == data.supervision_id
      );
      state.supervisiones.sin_supervisar[index].fecha_reagendada =
        data.fecha_reagendada;
    }
  },
  SET_GARANTIAS(state, garantias) {
    state.garantias = garantias;
  },
  SET_ARCHIVOS_GARANTIA(state, archivos) {
    state.archivos_garantia = archivos;
  },
  SET_PASIVAS(state, pasivas) {
    state.pasivas = pasivas;
  },
  SET_ACTIVAS(state, activas) {
    state.activas = activas;
  },
  SET_FUENTES_FONDEO(state, fuentes) {
    state.fuentes_fondeo = fuentes;
  },
  SET_CASTIGO(state, castigo) {
    if (Array.isArray(castigo)) {
      state.castigo = castigo;
    } else {
      state.castigo.push(castigo);
    }
  },
  SET_ESTADO_CUENTA(state, estado) {
    this.estado_cuenta = estado;
  },
  SET_TABLA_AMORTIZACIONES(state, amortizaciones) {
    this.tabla_amortizaciones = amortizaciones;
  },
  SET_REFINANCIAMIENTO_VENCIDO(state, configuracion) {
    state.refinanciamiento_vencido = configuracion;
  },
  SET_CUENTA_PRIMA(state, cuenta) {
    state.cuenta_prima = cuenta;
  },
  DELETE_PAGO(state, pago) {
    const pago_id = pago;
    const index = state.pagos.findIndex(pago => pago.id == pago_id);

    state.pagos.splice(index, 1);
  },
  SET_CUENTAS_DISPOSICION(state, cuentas) {
    state.cuentas = cuentas;
  },
  SET_CUENTA(state, cuenta) {
    state.cuenta = cuenta;
  },
  SET_CUENTA_FINANCIADA(state, cuenta) {
    state.cuenta_financiada = cuenta;
  },
  SET_CUENTAS_FINANCIADAS(state, cuentas) {
    state.cuentas_financiadas = cuentas;
  }
};
const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  crearDisposicion({ commit }, data) {
    return DisposicionApi.crearDisposicion(
      data,
      res => {
        commit("SET_DISPOSICION", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  editarDisposicion({ commit }, data) {
    return DisposicionApi.editarDisposicion(
      data,
      res => {
        commit("SET_DISPOSICION", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getDisposiciones({ commit }, params) {
    let tipo = params.tipo;
    let pagina = params.pagina ? params.pagina : 1;
    let filtro = null;
    if (params.filtro) {
      filtro = params.filtro;
    }
    return DisposicionApi.getDisposiciones(
      tipo,
      filtro,
      pagina,
      params.linea_id,
      res => {
        if (filtro) {
          commit("SET_DISPOSICIONES_FILTRADAS", res.data);
        } else {
          commit("SET_DISPOSICIONES", res.data);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getDisposicionDetalle({ commit }, id) {
    return DisposicionApi.getDisposicionDetalle(
      id,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTablaAmortizacion({ commit }, id) {
    return DisposicionApi.getTablaAmortizacion(
      id,
      res => {
        commit("SET_TABLA_AMORTIZACION", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  changeProspectoEspera({ commit }, data) {
    return DisposicionApi.changeProspectoEspera(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  changeProspectoEsperaMasivo({ commit }, data) {
    return DisposicionApi.changeProspectoEsperaMasivo(
      data,
      res => {
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  changeEsperaAutorizado({ commit }, data) {
    return DisposicionApi.changeEsperaAutorizado(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  changeEsperaAutorizadoMasivo({ commit }, data) {
    return DisposicionApi.changeEsperaAutorizadoMasivo(
      data,
      res => {
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  changeAutorizadoEntregadoMasivo({ commit }, data) {
    if (data.pasivo == true) {
      return DisposicionApi.changeAutorizadoEntregadoPasivoMasivo(
        data,
        res => {
          commit("SET_SUCCESS", true);
          return res.data;
        },
        () => {
          return false;
        }
      );
    }
    return DisposicionApi.changeAutorizadoEntregadoActivoMasivo(
      data,
      res => {
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  changeAutorizadoEntregado({ commit }, data) {
    if (data.pasivo == true) {
      return DisposicionApi.changeAutorizadoEntregadoPasivo(
        data,
        res => {
          commit("SET_DISPOSICION_DETALLE", res.data);
          commit("SET_SUCCESS", true);
          return res.data;
        },
        () => {
          return false;
        }
      );
    }
    return DisposicionApi.changeAutorizadoEntregadoActivo(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  clearDisposicionesFiltradas({ commit }) {
    commit("CLEAR_DISPOSICIONES_FILTRADAS");
    return true;
  },
  setFiltros({ commit }, filtros) {
    commit("SET_FILTROS", filtros);
    return true;
  },
  changeStatus({ commit }, status) {
    commit("CHANGE_STATUS", status);
    commit("SET_SUCCESS", false);
    return true;
  },
  changeProcesarOperaciones({ commit }, status) {
    commit("CHANGE_PROCESAR_OPERACIONES", status);
    commit("SET_SUCCESS", false);
    return true;
  },
  declinar({ commit }, data) {
    return DisposicionApi.declinar(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  archivar({ commit }, data) {
    return DisposicionApi.archivar(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        commit("SET_SUCCESS", true);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  eliminar({ commit }, data) {
    return DisposicionApi.eliminar(
      data,
      () => {
        commit("RESET_STATE");
        commit("SET_SUCCESS", true);
        return true;
      },
      () => {
        return false;
      }
    );
  },
  simularTablaAmortizacion({ commit }, data) {
    return DisposicionApi.simularTablaAmortizacion(
      data,
      res => {
        commit("SET_TABLA_AMORTIZACION", res.data);
        return res.data;
      },
      () => {
        return false;
      }
    );
  },
  ligarArchivos({ commit }, form) {
    return DisposicionApi.ligarArchivos(
      form,
      res => {
        commit("SET_DOCUMENTOS", res.data.archivos);
        return res.data;
      },
      () => false
    );
  },
  desligarDocumentos({ commit }, data) {
    return DisposicionApi.desligarArchivos(
      data,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getDocumentos({ commit }, id) {
    return DisposicionApi.getDocumentos(
      id,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getBitacora({ commit }, id) {
    return DisposicionApi.getBitacora(
      id,
      res => {
        commit("SET_BITACORA", res.data);
        return res.data;
      },
      () => false
    );
  },
  agregarComentario({ commit }, data) {
    return DisposicionApi.agregarComentario(
      data,
      res => {
        commit("SET_BITACORA", res.data);
        return res.data;
      },
      () => false
    );
  },
  getOperacionesDiarias({ commit }, disposicion_id) {
    return DisposicionApi.getOperacionesDiarias(
      disposicion_id,
      res => {
        commit("SET_OPERACIONES_DIARIAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getCredito({ commit }, disposicion_id) {
    return DisposicionApi.getCredito(
      disposicion_id,
      res => {
        commit("SET_CREDITO", res.data);
        return res.data;
      },
      () => false
    );
  },
  getPagos({ commit }, disposicion_id) {
    return DisposicionApi.getPagos(
      disposicion_id,
      res => {
        commit("SET_PAGOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  deletePago({ commit }, pago_id) {
    return PagosApi.deletePago(
      pago_id,
      res => {
        commit("DELETE_PAGO", pago_id);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getSupervisiones({ commit }, disposicion_id) {
    return DisposicionApi.getSupervisiones(
      disposicion_id,
      res => {
        commit("SET_SUPERVISIONES", res.data);
        return res.data;
      },
      () => false
    );
  },
  getGarantias({ commit }, disposicion_id) {
    return DisposicionApi.getGarantias(
      disposicion_id,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  asignarGarantia({ commit }, data) {
    return DisposicionApi.asignarGarantia(
      data,
      res => {
        commit("SET_GARANTIAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  archivosGarantiaNueva({ commit }, data) {
    commit("SET_ARCHIVOS_GARANTIA", data);
  },
  ligarDisposicionPasiva({ commit }, data) {
    return DisposicionApi.ligarDisposicionPasiva(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        return res.data;
      },
      () => false
    );
  },
  getPasivas({ commit }, disposicion_id) {
    return DisposicionApi.getPasivas(
      disposicion_id,
      res => {
        commit("SET_PASIVAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getActivas({ commit }, disposicion_id) {
    return DisposicionApi.getActivas(
      disposicion_id,
      res => {
        commit("SET_ACTIVAS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getFuentesFondeo({ commit }, disposicion_id) {
    return DisposicionApi.getFuentesFondeo(
      disposicion_id,
      res => {
        commit("SET_FUENTES_FONDEO", res.data);
        return res.data;
      },
      () => false
    );
  },
  asignarFuenteFondeo({ commit }, data) {
    return DisposicionApi.asignarFuenteFondeo(
      data,
      res => {
        commit("SET_FUENTES_FONDEO", res.data.lineas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  cambiarTasaInteresAjeno({ commit }, data) {
    return DisposicionApi.cambiarTasaInteresAjeno(
      data,
      res => {
        commit("SET_DISPOSICION_DETALLE", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setCastigo({ commit }, data) {
    return DisposicionApi.setCastigo(
      data,
      res => {
        commit("SET_CASTIGO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCastigos({ commit }, disposicion_id) {
    return DisposicionApi.getCastigos(
      disposicion_id,
      res => {
        commit("SET_CASTIGO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  printEstadoCuenta({ commit }, data) {
    return DisposicionApi.printEstadoCuenta(
      data,
      res => {
        let ticketWindow = null;
        if (res.data.pdf) {
          commit("SET_ESTADO_CUENTA", res.data.pdf);
          ticketWindow = window.open(
            "",
            "TicketWindow",
            "width=500,height=700"
          );
          // let obj = ticketWindow.document.createElement("object");
          // obj.style.width = "100%";
          // obj.style.height = "842pt";
          // obj.type = "application/pdf";
          // obj.data = "data:application/pdf;base64," + res.data.pdf;
          ticketWindow.document.title = "Estado de cuenta";
          const blob = base64ToBlob(res.data.pdf, 'application/pdf');
          const url = URL.createObjectURL(blob);
          ticketWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
          // ticketWindow.document.body.appendChild(obj);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  printTablaAmortizaciones({ commit }, data) {
    return DisposicionApi.printTablaAmortizaciones(
      data,
      res => {
        let ticketWindow = null;
        if (res.data.pdf) {
          commit("SET_TABLA_AMORTIZACIONES", res.data.pdf);
          ticketWindow = window.open(
            "",
            "TicketWindow",
            "width=500,height=700"
          );
          let obj = ticketWindow.document.createElement("object");
          obj.style.width = "100%";
          obj.style.height = "842pt";
          obj.type = "application/pdf";
          obj.data = "data:application/pdf;base64," + res.data.pdf;
          ticketWindow.document.title = "Tabla de amortizaciones";
          ticketWindow.document.body.appendChild(obj);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  printSimulacion({ commit }, data){
    return DisposicionApi.printSimulacion(
      data,
      res => {
        let ticketWindow = null;
        if (res.data.pdf) {
          commit("SET_TABLA_AMORTIZACIONES", res.data.pdf);
          ticketWindow = window.open(
            "",
            "TicketWindow",
            "width=500,height=700"
          );
          let obj = ticketWindow.document.createElement("object");
          obj.style.width = "100%";
          obj.style.height = "842pt";
          obj.type = "application/pdf";
          obj.data = "data:application/pdf;base64," + res.data.pdf;
          ticketWindow.document.title = "Tabla de amortizaciones";
          ticketWindow.document.body.appendChild(obj);
        }
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  toggleRefinanciamientoVencido({ commit }, disposicion_id) {
    return DisposicionApi.toggleRefinanciamientoVencido(
      disposicion_id,
      res => {
        commit(
          "SET_REFINANCIAMIENTO_VENCIDO",
          res.data.refinanciamiento_vencido
        );
      },
      error => {
        return error;
      }
    );
  },
  getRefinanciamientoVencido({ commit }, disposicion_id) {
    return DisposicionApi.getRefinanciamientoVencido(
      disposicion_id,
      res => {
        commit(
          "SET_REFINANCIAMIENTO_VENCIDO",
          res.data.refinanciamiento_vencido
        );
      },
      error => {
        return error;
      }
    );
  },
  generarCuentaPrima({ commit }, data) {
    return DisposicionApi.generarCuentaPrima(
      data,
      res => {
        commit("SET_CUENTA_PRIMA", res.data);
      },
      error => {
        return error;
      }
    );
  },
  financiarCuenta({ commit }, data) {
    return DisposicionApi.financiarCuenta(
      data,
      res => {
        commit("SET_CUENTA_FINANCIADA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setCuenta({ commit }, cuenta) {
    commit("SET_CUENTA", cuenta);
  },
  getCuentas({ commit }, disposicion_id) {
    return DisposicionApi.getCuentas(
      disposicion_id,
      res => {
        commit("SET_CUENTAS_DISPOSICION", res.data);
        return res.data;
      },
      () => false
    );
  },
  getCuentasFinanciadas({ commit }, disposicion_id) {
    return DisposicionApi.getCuentasFinanciadas(
      disposicion_id,
      res => {
        commit("SET_CUENTAS_FINANCIADAS", res.data);
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
