import CatalogosApi from "@/api/catalogos.api";

const state = {
  monedas: [],
  productos_financieros: [],
  tipos_pago: [
    {
      alias: "Pago unico de capital al vencimiento",
      clave: "vencimiento",
      descripcion: "",
      id: 1
    },
    {
      alias: "Multiples amortizaciones",
      clave: "irregulares",
      descripcion: "",
      id: 3
    }
  ],
  periodos_regulares: [],
  tasas_base: [],
  costos_extras: [],
  tratamientos_interes: [],
  fechas_corte: [
    { id: 0, nombre: "Fin de mes" },
    { id: 1, nombre: "Fecha de aniversario" }
  ],
  formas_calculo: [
    { id: 1, nombre: "Entrada si, salida no" },
    { id: 0, nombre: "Entrada no, salida si" }
  ],
  formas_calculo_moratorio: [
    { id: 0, nombre: "Manual" },
    { id: 1, nombre: "En base al factor del interes ordinario" }
  ],
  tipos_tiempo: [
    { id: "days", nombre: "Días" },
    { id: "months", nombre: "Meses" },
    { id: "years", nombre: "Años" }
  ],
  tipos_inhabil: [
    { id: 0, nombre: "Día habil anterior" },
    { id: 1, nombre: "Día habil siguiente" }
  ],
  creditos_status: null,
  ejecutivos: null,
  clientes: null,
  destino_recursos: null,
  clientes_lineas_credito: null,
  instrumentos_monetarios: null,
  tipos_garantia: null,
  tipos_garantia_portafolio: null,
  tipos_garantia_linea: null,
  atributos_tipo_garantia: null,
  fuentes_fondeo: null,
  tipos_saldo: null,
  instituciones_financieras: null,
  submodulo: null,
  parametros_personalizables: null,
  estados: null,
  municipios: null,
  localidades: null,
  linea_status: null,
  submodulos: null,
  segmentos_negocio: null,
  tipos_requisito: null,
  requisito_status: null,
  personas: null,
  roles: null,
  grupos_economicos_internos: null
};
const getters = {
  getMonedas: state => state.monedas,
  getProductosFinancieros: state => state.productos_financieros,
  getTiposPago: state => state.tipos_pago,
  getPeriodosRegulares: state => state.periodos_regulares,
  getTasasBase: state => state.tasas_base,
  getCostosExtras: state => state.costos_extras,
  getTratamientoIntereses: state => state.tratamientos_interes,
  getFechasCorte: state => state.fechas_corte,
  getFormasCalculo: state => state.formas_calculo,
  getFormasCalculoMoratorio: state => state.formas_calculo_moratorio,
  getTiposTiempo: state => state.tipos_tiempo,
  getTiposInhabil: state => state.tipos_inhabil,
  getCreditoStatus: state => state.creditos_status,
  getEjecutivos: state => state.ejecutivos,
  getClientes: state => state.clientes,
  getClientesConLineasCredito: state => state.clientes_lineas_credito,
  getClientesLineasCreditoProducto: state => state.clientes_lineas_credito,
  getDestinoRecursos: state => state.destino_recursos,
  getInstrumentosMonetarios: state => state.instrumentos_monetarios,
  getTiposGarantia: state => state.tipos_garantia,
  getTiposGarantiaPortafolio: state => state.tipos_garantia_portafolio,
  getTiposGarantiaLinea: state => state.tipos_garantia_linea,
  getAtributosTipoGarantia: state => state.atributos_tipo_garantia,
  getFuentesFondeo: state => state.fuentes_fondeo,
  getTiposSaldo: state => state.tipos_saldo,
  getInstitucionesFinancieras: state => state.instituciones_financieras,
  getSubmodulo: state => state.submodulo,
  getParametrosPersonalizables: state => state.parametros_personalizables,
  getEstados: state => state.estados,
  getMunicipios: state => state.municipios,
  getLocalidades: state => state.localidades,
  getLineaStatus: state => state.linea_status,
  getSubmodulos: state => state.submodulos,
  getSegmentoNegocio: state => state.segmentos_negocio,
  getTiposRequisito: state => state.tipos_requisito,
  getRequisitoStatus: state => state.requisito_status,
  getPersonas: state => state.personas,
  getRoles: state => state.roles,
  getGruposEconomicosInternos: state => state.grupos_economicos_internos
};
const mutations = {
  SET_MONEDAS(state, monedas) {
    state.monedas = monedas;
  },
  SET_PRODUCTOS_FINANCIEROS(state, productos) {
    state.productos_financieros = productos;
  },
  SET_TIPOS_PAGO(state, tipos) {
    state.tipos_pago = tipos;
  },
  SET_PERIODOS_REGULARES(state, periodos) {
    state.periodos_regulares = periodos;
  },
  SET_TASAS_BASE(state, tasas) {
    state.tasas_base = tasas;
  },
  SET_COSTOS_EXTRAS(state, costos) {
    state.costos_extras = costos;
  },
  SET_TRATAMIENTOS_INTERES(state, tratamientos) {
    state.tratamientos_interes = tratamientos;
  },
  SET_CREDITOS_STATUS(state, creditos_status) {
    state.creditos_status = creditos_status;
  },
  SET_EJECUTIVOS(state, ejecutivos) {
    state.ejecutivos = ejecutivos;
  },
  SET_CLIENTES(state, clientes) {
    state.clientes = clientes;
  },
  SET_CLIENTES_LINEAS_CREDITO(state, clientes) {
    state.clientes_lineas_credito = clientes;
  },
  SET_CLIENTES_LINEAS_CREDITO_PRODUCTO(state, clientes) {
    state.clientes_lineas_credito = clientes;
  },
  SET_DESTINO_RECURSOS(state, destino) {
    state.destino_recursos = destino;
  },
  SET_INSTRUMENTOS_MONETARIOS(state, instrumentos) {
    state.instrumentos_monetarios = instrumentos;
  },
  SET_TIPOS_GARANTIA(state, tipos) {
    state.tipos_garantia = tipos;
  },
  SET_TIPOS_GARANTIA_PORTAFOLIO(state, tipos) {
    state.tipos_garantia_portafolio = tipos;
  },
  SET_TIPOS_GARANTIA_LINEA(state, tipos) {
    state.tipos_garantia_linea = tipos;
  },
  SET_ATRIBUTOS_TIPO_GARANTIA(state, atributos) {
    state.atributos_tipo_garantia = atributos;
  },
  SET_FUENTES_FONDEO(state, fdf) {
    state.fuentes_fondeo = fdf;
  },
  SET_TIPOS_SALDO(state, tipos_saldo) {
    state.tipos_saldo = tipos_saldo;
  },
  SET_INSTITUCIONES_FINANCIERAS(state, instituciones_financieras) {
    state.instituciones_financieras = instituciones_financieras;
  },
  SET_SUBMODULO(state, submodulo) {
    state.submodulo = submodulo;
  },
  SET_PARAMETROS_PERSONALIZABLES(state, params) {
    state.parametros_personalizables = params;
  },
  SET_ESTADOS(state, estados) {
    state.estados = estados;
  },
  SET_MUNICIPIOS(state, municipios) {
    state.municipios = municipios;
  },
  SET_LOCALIDADES(state, localidades) {
    state.localidades = localidades;
  },
  SET_LINEA_STATUS(state, linea_status) {
    state.linea_status = linea_status;
  },
  SET_SUBMODULOS(state, submodulos) {
    state.submodulos = submodulos;
  },
  SET_SEGMENTOS_NEGOCIO(state, segmentos) {
    state.segmentos_negocio = segmentos;
  },
  SET_TIPOS_REQUISITO(state, tipos) {
    state.tipos_requisito = tipos;
  },
  SET_REQUISITO_STATUS(state, status) {
    state.requisito_status = status;
  },
  SET_PERSONAS(state, personas) {
    state.personas = personas;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_GRUPOS_ECONOMICOS_INTERNOS(state, grupos_economicos_internos) {
    state.grupos_economicos_internos = grupos_economicos_internos;
  }
};
const actions = {
  getMonedas({ commit }) {
    return CatalogosApi.getMonedas(
      res => {
        commit("SET_MONEDAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getProductosFinancieros({ commit }) {
    return CatalogosApi.getProductosFinancieros(
      res => {
        commit("SET_PRODUCTOS_FINANCIEROS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  // getTiposPago({ commit }) {
  //   return CatalogosApi.getTiposPago(
  //     res => {
  //       commit("SET_TIPOS_PAGO", res.data);
  //       return res.data;
  //     },
  //     error => {
  //       return error;
  //     }
  //   );
  // },
  getPeriodosRegulares({ commit }) {
    return CatalogosApi.getPeriodosRegulares(
      res => {
        commit("SET_PERIODOS_REGULARES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTasasBase({ commit }) {
    return CatalogosApi.getTasasBase(
      res => {
        commit("SET_TASAS_BASE", res.data.tasas_base);
        return res.data.tasas_base;
      },
      error => {
        return error;
      }
    );
  },
  getCostosExtras({ commit }) {
    return CatalogosApi.getCostosExtras(
      res => {
        commit("SET_COSTOS_EXTRAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTratamientoIntereses({ commit }) {
    return CatalogosApi.getTratamientoIntereses(
      res => {
        commit("SET_TRATAMIENTOS_INTERES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCreditoStatus({ commit }, tipo) {
    return CatalogosApi.getCreditoStatus(
      tipo,
      res => {
        commit("SET_CREDITOS_STATUS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getLineaStatus({ commit }) {
    return CatalogosApi.getLineaStatus(
      res => {
        commit("SET_LINEA_STATUS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getEjecutivos({ commit }) {
    return CatalogosApi.getEjecutivos(
      res => {
        commit("SET_EJECUTIVOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getClientes({ commit }) {
    return CatalogosApi.getClientes(
      res => {
        commit("SET_CLIENTES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getClientesConLineasCredito({ commit }) {
    return CatalogosApi.getClientesLineasCredito(
      res => {
        commit("SET_CLIENTES_LINEAS_CREDITO", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getClientesLineasCreditoProducto({ commit }, producto_financiero_id) {
    return CatalogosApi.getClientesLineasCreditoProducto(
      producto_financiero_id,
      res => {
        commit("SET_CLIENTES_LINEAS_CREDITO_PRODUCTO", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  buscarClientesLineasCredito({ commit }, nombre) {
    return CatalogosApi.buscarClientesLineasCredito(
      nombre,
      res => {
        commit("SET_CLIENTES_LINEAS_CREDITO", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  buscarClientes({ commit }, nombre) {
    return CatalogosApi.buscarClientes(
      nombre,
      res => {
        commit("SET_CLIENTES", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  buscarPersonas({ commit }, nombre) {
    return CatalogosApi.buscarPersonas(
      nombre,
      res => {
        commit("SET_PERSONAS", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getDestinoRecursos({ commit }) {
    return CatalogosApi.getDestinoRecursos(
      res => {
        commit("SET_DESTINO_RECURSOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getInstrumentosMonetarios({ commit }) {
    return CatalogosApi.getInstrumentosMonetarios(
      res => {
        commit("SET_INSTRUMENTOS_MONETARIOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTiposGarantia({ commit }) {
    return CatalogosApi.getTiposGarantia(
      res => {
        commit("SET_TIPOS_GARANTIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTiposGarantiaPortafolio({ commit }) {
    return CatalogosApi.getTiposGarantiaPortafolio(
      res => {
        commit("SET_TIPOS_GARANTIA_PORTAFOLIO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTiposGarantiaLinea({ commit }) {
    return CatalogosApi.getTiposGarantiaLinea(
      res => {
        commit("SET_TIPOS_GARANTIA_LINEA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getAtributosTipoGarantia({ commit }, tipo_garantia_id) {
    return CatalogosApi.getAtributosTipoGarantia(
      tipo_garantia_id,
      res => {
        commit("SET_ATRIBUTOS_TIPO_GARANTIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getFuentesFondeo({ commit }, nombre) {
    return CatalogosApi.getFuentesFondeo(
      nombre,
      res => {
        commit("SET_FUENTES_FONDEO", res.data.personas);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTiposSaldo({ commit }) {
    return CatalogosApi.getTiposSaldo(
      res => {
        commit("SET_TIPOS_SALDO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setOrdenPrelacionSaldos({ commit }, saldos_ordenados) {
    return CatalogosApi.setOrdenPrelacionSaldos(
      saldos_ordenados,
      res => {
        commit("SET_TIPOS_SALDO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getInstitucionesFinancieras({ commit }) {
    return CatalogosApi.getInstitucionesFinancieras(
      res => {
        commit("SET_INSTITUCIONES_FINANCIERAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getSubmodulo({ commit }, submodulo_id) {
    return CatalogosApi.getSubmodulo(
      submodulo_id,
      res => {
        commit("SET_SUBMODULO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getSubmodulos({ commit }) {
    return CatalogosApi.getSubmodulos(
      res => {
        commit("SET_SUBMODULOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getParametrosPersonalizables({ commit }) {
    return CatalogosApi.getParametrosPersonalizables(
      res => {
        commit("SET_PARAMETROS_PERSONALIZABLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getEstados({ commit }) {
    return CatalogosApi.getEstados(
      res => {
        commit("SET_ESTADOS", res.data.estados);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getMunicipios({ commit }, estado_id) {
    return CatalogosApi.getMunicipios(
      estado_id,
      res => {
        commit("SET_MUNICIPIOS", res.data.municipios);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getLocalidades({ commit }, municipio_id) {
    return CatalogosApi.getLocalidades(
      municipio_id,
      res => {
        commit("SET_LOCALIDADES", res.data.localidades);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getSegmentoNegocio({ commit }) {
    return CatalogosApi.getSegmentoNegocio(
      res => {
        commit("SET_SEGMENTOS_NEGOCIO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getTiposRequisito({ commit }) {
    return CatalogosApi.getTiposRequisito(
      res => {
        commit("SET_TIPOS_REQUISITO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRequisitoStatus({ commit }) {
    return CatalogosApi.getRequisitoStatus(
      res => {
        commit("SET_REQUISITO_STATUS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRoles({ commit }) {
    return CatalogosApi.getRoles(
      res => {
        commit("SET_ROLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getGruposEconomicosInternos({ commit }) {
    return CatalogosApi.getGruposEconomicosInternos(
      res => {
        commit("SET_GRUPOS_ECONOMICOS_INTERNOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
