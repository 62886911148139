import ReportesApi from "@/api/reportes.api";

const state = {
  modelos: []
};
const getters = {
  getModelos: state => state.modelos
};
const mutations = {
  SET_MODELOS(state, modelos) {
    state.modelos = modelos;
  }
};
const actions = {
  getModelos({ commit }) {
    return ReportesApi.getModelos(
      res => {
        commit("SET_MODELOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
