<template>
  <nav
    class="breadcrumb mt-3 has-background-white ml-0"
    aria-label="breadcrumbs"
    v-if="(hasDisposicion && getDisposicionDetalle) || !hasDisposicion"
  >
    <ul>
      <li
        v-for="(crumb, index) in breadcrumbList"
        :key="index"
        class="capitalize"
        :class="{
          'is-active': breadcrumbList.length - 1 == index,
          'has-text-info cursor-pointer': breadcrumbList.length - 1 != index,
          'is-arrendamiento': esArrendamiento && !(breadcrumbList.length - 1 == index),
          
        }"
        @click="routeTo(crumb.path)"
      >
        <span>{{ crumb.text }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "breadcrumb",
  data() {
    return {
      breadcrumbList: [],
      hasDisposicion: false
    };
  },
  computed: {
    ...mapGetters("disposicion", ["getDisposicionDetalle"]),
    ...mapGetters("lineascredito", ["getLinea", "getMovimientos"]),
    routePath() {
      return this.$route.path;
    },
    esArrendamiento() {
      return this.getLinea ? this.getLinea.producto_financiero.tipo_credito.tipo === "Arrendamiento Puro" : false;
    }
  },
  watch: {
    routePath() {
      this.setCrumbs();
    }
  },
  methods: {
    /*
      Función que se encarga de llevar a la ruta indicada al darle click a un crumb
      @path (String) la ruta a ir
    */
    routeTo(path) {
      Object.keys(this.$route.params).forEach(param => {
        path = path.replace(param, this.$route.params[param]);
      });
      path = path.split(":").join("");
      if (path !== this.$route) {
        this.$router.push(path);
      }
    },
    //Obtiene los nombres de las rutas para mostrar en el breadcrumb junto con sus ids
    setCrumbs() {
      this.breadcrumbList = [];
      this.$route.matched.forEach(route => {
        let path = route.path.split(":");
        let crumb = null;
        if (path.length == 1) {
          if (path[0][0] == "/") {
            path[0] = path[0].substring(1);
          }
          let name = path[0].split("/");
          name[0] = name[0].split("-").join(" ");
          name = name.filter(Boolean);
          crumb = {
            text: name[name.length - 1],
            path: route.path
          };
        } else {
          let route_path = route.path;
          if (
            route.parent != undefined &&
            route_path.includes(route.parent.path)
          ) {
            route_path = route_path.replace(route.parent.path, "");
          }
          let index_path = route_path.indexOf(":");
          if (index_path >= 0) {
            let id = route_path.substring(index_path + 1);
            if (id == "idDisposicion") {
              this.hasDisposicion = true;
              if (this.getDisposicionDetalle != null)
                id = this.idToShow(this.getDisposicionDetalle);
            } else {
              id = this.$route.params[id];
            }
            let name = route_path.split(":")[0].split("/");
            name = name.filter(Boolean);
            let printed_name = name[0].split("-").join(" ");
            if (id != undefined) {
              printed_name += " #" + id;
            }
            crumb = {
              text: printed_name,
              path: route.path
            };
          } else if (route_path != "/") {
            crumb = {
              text: route_path.split("_").join(" "),
              path: route.path
            };
          }
        }
        if (crumb != null) {
          this.breadcrumbList.push(crumb);
        }
      });
    }
  },
  mounted() {
    if (
      this.getDisposicionDetalle == null &&
      this.$route.params.idDisposicion
    ) {
      this.$store
        .dispatch(
          "disposicion/getDisposicionDetalle",
          this.$route.params.idDisposicion
        )
        .then(() => this.setCrumbs());
    } else {
      this.setCrumbs();
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb li > span {
  margin: 0 0.4rem;
}
.breadcrumb li.is-active > span {
  color: var(--grey-darker);
}
.breadcrumb li.is-arrendamiento > span {
  color: #a855f7;
}
@media only screen and (max-width: 1024px) {
  .breadcrumb {
    margin-left: 1.8rem;
  }
}
</style>
