var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview bg-white shadow-capvital has-border-radius p-4 text-left"},[_c('b-button',{staticClass:"absolute right-0 mr-4 top-0 mt-4",attrs:{"icon-left":"times","type":"is-text"},on:{"click":_vm.closePreview}}),(_vm.tipo == 'persona')?_c('icon-initials',{attrs:{"nombre":_vm.titulo,"tipo":_vm.info.tipos_persona_id}}):(_vm.icon != null)?_c('b-icon',{staticClass:"p-2 rounded-full has-background-white",attrs:{"icon":_vm.icon,"size":"is-large"}}):_vm._e(),_c('p',{staticClass:"text-left text-2xl truncate mt-2",class:_vm.title_color},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),(
      _vm.tipo == 'credito' &&
        _vm.info.status.status.activo == 0 &&
        _vm.info.status.nombre != 'Finalizado'
    )?_c('div',{staticClass:"py-20"},[_c('h1',{staticClass:"text-center"},[_vm._v(" Sin información para mostrar ya que esta disposición aún no ha sido entregada. ")])]):_vm._e(),(_vm.total)?_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total))+" "+_vm._s(this.moneda))]):_vm._e(),_c('div',{staticClass:"font-normal"},_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"mb-1"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(field.field))]),(field.type == 'currency')?_c('p',{class:_vm.text_color},[_vm._v(" "+_vm._s(_vm._f("currency")(field.value))+" "+_vm._s(field.moneda)+" ")]):(field.type == 'date')?_c('p',{class:_vm.text_color},[(field.value != 'N/A')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(field.value,"DD-MM-YYYY"))+" ")]):_c('span',[_vm._v("N/A")])]):_c('p',{staticClass:"-mt-1",class:[field.class]},[_vm._v(" "+_vm._s(field.value)+" ")])])}),0),(_vm.tipo == 'cuentaCP')?_c('div',{staticClass:"my-3"},[_c('b-button',{staticClass:"font-regular w-full",attrs:{"type":"is-primary","tag":"router-link","to":{
        name: 'aplicarpago',
        params: {
          tipo: 'cuentaCP',
          idObjeto: _vm.info.id
        }
      }},nativeOn:{"click":function($event){return _vm.closePreview($event)}}},[_vm._v(" Aplicar pago ")])],1):_vm._e(),(
      _vm.tipo == 'credito' &&
        _vm.info.status.status.activo == 1 &&
        _vm.modulo_caja.activo == 1
    )?_c('div',{staticClass:"my-3"},[_c('b-button',{staticClass:"font-regular w-full",attrs:{"type":"is-primary","tag":"router-link","to":{
        name: 'aplicarpago',
        params: {
          tipo: 'disposicion',
          idObjeto: _vm.info.id
        }
      }},nativeOn:{"click":function($event){return _vm.closePreview($event)}}},[_vm._v(" Aplicar pago ")])],1):_vm._e(),(_vm.btn_enabled)?_c('div',{staticClass:"mt-3"},[_c('b-button',{attrs:{"type":_vm.btn_type,"expanded":"","tag":"a"},on:{"click":function($event){return _vm.goTo(_vm.link)}}},[_vm._v("Ver en detalle")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }