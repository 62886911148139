<template>
  <div
    class="p-3 has-border-radius document-card"
    :class="{
      'cursor-pointer': modal == true,
      'has-background-primary-02': checkbox == true,
      'has-background-white-ter': checkbox == false
    }"
    @click="toggleCheckbox"
  >
    <div class="columns is-vcentered">
      <div
        class="column flex items-center "
        :class="{ 'is-4': !small, 'is-11': small }"
      >
        <b-tooltip :label="doc.nombre" multilined type="is-dark">
          <b-icon size="is-medium" :icon="icon" :class="color" />
        </b-tooltip>
        <div class="w-full pr-8">
          <p class="font-bold inline-block ml-3 truncate mb-0 w-full">
            <a @click="goTo(doc.path)">
              {{ doc.nombre }}
            </a>
          </p>
          <p class="ml-3 text-sm -mt-2">#{{ doc.id }}</p>
        </div>
      </div>
      <div class="column is-3" v-if="!small">
        <p class="has-text-grey-light">Fecha de expiración</p>
        <p v-if="doc.fecha_expiracion != null">
          {{ doc.fecha_expiracion | moment("DD-MM-YY") }}
        </p>
        <p v-if="!doc.fecha_expiracion" class="has-text-danger text-sm">
          No definida
        </p>
      </div>
      <div class="column is-3" v-if="!small">
        <p class="has-text-grey-light">Fecha creación</p>
        <p v-if="doc.created_at != null">
          {{ doc.created_at | moment("DD-MM-YY") }}
        </p>
        <p v-if="!doc.created_at" class="has-text-danger text-sm">
          No definida
        </p>
      </div>
      <div class="column is-4" v-if="!small">
        <p class="has-text-grey-light">Descripción</p>
        <p>{{ doc.descripcion || "--" }}</p>
      </div>
      <div
        class="column is-1 text-right md:text-left"
        v-if="modal"
        @click="toggleCheckbox"
      >
        <b-checkbox size="is-medium" v-model="checkbox" />
      </div>
      <div v-else class="text-right column is-1">
        <b-dropdown
          aria-role="list"
          position="is-bottom-left"
          class="cursor-pointer text-left"
        >
          <fa-icon icon="ellipsis-v" class="mr-4" slot="trigger" />
          <b-dropdown-item
            aria-role="menuitem"
            v-if="small"
            custom
            class="text-left"
          >
            <p>
              Fecha de expiración:
            </p>
            <span v-if="doc.fecha_expiracion != null">{{
              doc.fecha_expiracion | moment("DD-MM-YY")
            }}</span>
            <span class="has-text-danger" v-else>No definida</span>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="menuitem"
            v-if="small"
            custom
            class="text-left"
          >
            <p>
              Fecha de creación:
            </p>
            <span v-if="doc.created_at != null">{{
              doc.created_at | moment("DD-MM-YY")
            }}</span>
            <span class="has-text-danger" v-else>No definida</span>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="menuitem"
            v-if="small"
            custom
            class="text-left"
          >
            <p>
              Descripción:
            </p>
            <span>
              {{ doc.descripcion || "--" }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            @click="desligarDocumento(doc.id)"
            class="has-text-danger"
          >
            Desligar
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { goTo } from "@/vendors/helpers";

export default {
  name: "DocumentoCard",
  props: {
    doc: Object,
    modal: {
      type: Boolean,
      default: false
    },
    checkActive: Boolean,
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      color: null,
      icon: "file",
      checkbox: false
    };
  },
  watch: {
    checkbox: function() {
      this.sendToParent();
    },
    checkActive: function(val) {
      this.checkbox = val;
    }
  },
  methods: {
    sendToParent() {
      this.$emit("checked", {
        file: this.doc,
        status: this.checkbox
      });
    },
    goTo(url) {
      goTo(url);
    },
    toggleCheckbox() {
      if (this.modal) {
        this.checkbox = !this.checkbox;
      }
    },
    desligarDocumento(id) {
      this.$emit("desligar", id);
    },
    setIcon() {
      let pos = this.doc.nombre.match(/\./gi).length;
      switch (this.doc.nombre.split(".")[pos]) {
        case "txt":
          this.color = "has-text-grey";
          this.icon = "file-alt";
          break;
        case "docx":
        case "doc":
        case "dot":
        case "docm":
        case "dotx":
        case "dotm":
        case "docb":
          this.color = "has-text-link";
          this.icon = "file-word";
          break;
        case "csv":
          this.color = "has-text-warning";
          this.icon = "file-csv";
          break;
        case "pdf":
          this.color = "has-text-danger";
          this.icon = "file-pdf";
          break;
        case "pptx":
        case "ppt":
        case "pot":
        case "pps":
        case "pptm":
        case "potx":
        case "potm":
        case "ppam":
        case "ppsx":
        case "ppsm":
        case "sldx":
        case "sldm":
          this.color = "has-text-warning";
          this.icon = "file-powerpoint";
          break;
        case "xls":
        case "xlt":
        case "xlm":
        case "xlsx":
        case "xlsm":
        case "xltx":
        case "xltm":
          this.color = "has-text-success";
          this.icon = "file-excel";
          break;
        case "png":
        case "jpeg":
        case "jpg":
        case "gif":
        case "svg":
          this.color = "has-text-pasivo";
          this.icon = "file-image";
      }
      return "file";
    }
  },
  mounted() {
    this.setIcon();
    this.checkbox = this.checkActive;
  }
};
</script>

<style lang="scss">
.document-card.cursor-pointer:not(.has-background-primary-02) {
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: var(--grey-lighter) !important;
  }
}
</style>
