import Vue from "vue";
import VueRouter from "vue-router";
import Tablas from "../views/Tablas.vue";
import store from "@/store";
import config from "@/../config";
import NProgress from "nprogress";
import EmptyRouterView from "@/components/generals/EmptyRouterView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "panel",
    component: () => (window.location = `${process.env.VUE_APP_CLIENT}/`),
    meta: {
      breadcrumb: [{ name: "Panel" }],
      modulo: "Panel"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      breadcrumb: [{ name: "Login" }]
    }
  },
  {
    path: "/tablas",
    name: "tablas",
    component: Tablas,
    meta: {
      breadcrumb: [{ name: "Reportes" }]
    }
  },
  {
    path: "/pago/:idPago",
    name: "detallepagoroot",
    component: () => import("../views/tesoreria/DetallePago.vue"),
    meta: {
      modulo: "Tesoreria"
    }
  },
  {
    path: "/aplicar-pago/:tipo/:idObjeto",
    name: "aplicarpago",
    component: () => import("../views/tesoreria/AplicarPago.vue"),
    meta: {
      modulo: "Tesoreria"
    }
  },
  {
    path: "/configuracion",
    component: EmptyRouterView,
    children: [
      {
        name: "configuracion",
        path: ""
      },
      {
        name: "config-tesoreria",
        path: "tesoreria",
        component: () => import("../views/configuracion/Tesoreria.vue"),
        meta: {
          modulo: "Configuración",
          submodulo: "Tesoreria"
        }
      }
    ]
  },
  {
    path: "/tesoreria",
    component: EmptyRouterView,
    children: [
      {
        name: "tesoreria",
        path: ""
      },
      {
        name: "caja",
        path: "caja",
        component: () => import("../views/tesoreria/Caja.vue"),
        meta: {
          modulo: "Tesoreria",
          submodulo: "Caja"
        }
      }
    ]
  },
  {
    path: "/disposiciones",
    component: EmptyRouterView,
    children: [
      {
        name: "disposiciones",
        path: "",
        component: () =>
          import("../views/creditos/disposiciones/Disposiciones.vue"),
        meta: {
          modulo: "Créditos",
          submodulo: "Control de disposiciones"
        }
      },
      {
        path: "solicitar-disposicion/:linea_credito_id?",
        name: "solicitar_disposicion",
        component: () =>
          import("../views/creditos/disposiciones/SolicitarDisposicion.vue"),
        meta: {
          modulo: "Créditos"
        }
      },
      {
        path: "solicitar-arrendamiento/:linea_credito_id?",
        name: "solicitar_arrendamiento",
        component: () =>
          import("../views/creditos/disposiciones/SolicitarDisposicion.vue"),
        meta: {
          modulo: "Créditos"
        }
      },
      {
        path: "solicitar-credito-grupal/:linea_credito_id?",
        name: "solicitar_credito_grupal",
        component: () =>
          import("../views/creditos/disposiciones/SolicitarDisposicion.vue"),
        meta: {
          modulo: "Créditos"
        }
      },
      {
        path: "/disposicion/:idDisposicion",
        component: EmptyRouterView,
        children: [
          {
            path: "",
            name: "disposicion",
            component: () =>
              import("../views/creditos/disposiciones/DetalleDisposicion.vue"),
            meta: {
              modulo: "Créditos"
            },
            children: [
              {
                path: "cuenta_disposicion",
                name: "cuenta_disposicion",
                component: () =>
                  import("../views/cuentas/CuentaCobrarPagar.vue"),
                meta: {
                  modulo: "Créditos"
                }
              }
            ]
          },
          {
            path: "editar-disposicion/:idEditarDisposicion",
            name: "editar_disposicion",
            component: () =>
              import(
                "../views/creditos/disposiciones/SolicitarDisposicion.vue"
              ),
            meta: {
              modulo: "Créditos"
            }
          },
          {
            path: "financiar",
            name: "financiar_cuenta",
            component: () =>
              import(
                "../views/creditos/disposiciones/SolicitarDisposicion.vue"
              ),
            meta: {
              modulo: "Créditos"
            }
          },
          {
            path: "fondear",
            name: "fondear_disposicion",
            component: () =>
              import(
                "../views/creditos/disposiciones/SolicitarDisposicion.vue"
              ),
            meta: {
              modulo: "Créditos"
            }
          },
          {
            path: "pago/:idPago",
            name: "detallepago",
            component: () => import("../views/tesoreria/DetallePago.vue"),
            meta: {
              modulo: "Créditos"
            }
          },
          {
            path: "supervision/:idSupervision",
            name: "detallesupervision",
            component: () =>
              import("../views/creditos/disposiciones/DetalleSupervision.vue"),
            meta: {
              modulo: "Créditos"
            }
          }
        ]
      }
    ]
  },
  {
    path: "/lineas-credito",
    component: EmptyRouterView,
    children: [
      {
        name: "lineascredito",
        path: "",
        component: () => import("../views/creditos/lineas/LineasCredito.vue"),
        meta: {
          modulo: "Créditos",
          submodulo: "Líneas de crédito"
        }
      },
      {
        path: "/linea/:idLinea",
        component: EmptyRouterView,
        children: [
          {
            path: "",
            name: "detalle_linea",
            component: () =>
              import("../views/creditos/lineas/DetalleLinea.vue"),
            meta: {
              modulo: "Créditos",
              submodulo: "Líneas de crédito"
            },
            children: [
              {
                path: "colaboradores",
                name: "colaboradores_linea",
                component: () =>
                  import("../views/creditos/lineas/Colaboradores.vue"),
                meta: {
                  modulo: "Créditos",
                  submodulo: "Líneas de crédito"
                }
              },
              {
                path: "cuenta_linea",
                name: "cuenta_linea",
                component: () =>
                  import("../views/cuentas/CuentaCobrarPagar.vue"),
                meta: {
                  modulo: "Créditos",
                  submodulo: "Líneas de crédito"
                }
              },
              {
                path: "contrato",
                name: "contrato",
                component: () =>
                  import("../views/creditos/lineas/Contrato.vue"),
                meta: {
                  modulo: "Créditos",
                  submodulo: "Líneas de crédito"
                }
              },
              {
                path: "cambio_status",
                name: "CambioStatusLinea",
                component: () =>
                  import("../views/creditos/lineas/CambioStatus.vue"),
                meta: {
                  modulo: "Créditos",
                  submodulo: "Líneas de crédito"
                }
              }
            ]
          },
          {
            path: "checklist",
            name: "checklist_linea",
            component: () => import("../views/creditos/lineas/Checklist.vue"),
            meta: {
              modulo: "Créditos",
              submodulo: "Líneas de crédito"
            }
          }
        ]
      },
      {
        path: "crear",
        name: "crear_linea",
        component: () => import("../views/creditos/lineas/CrearLinea.vue"),
        props: { pasiva: false },
        meta: {
          modulo: "Créditos",
          submodulo: "Líneas de crédito"
        }
      },
      {
        path: "editar/:idLinea",
        name: "editar_linea",
        component: () => import("../views/creditos/lineas/CrearLinea.vue"),
        meta: {
          modulo: "Créditos",
          submodulo: "Líneas de crédito"
        }
      },
    ]
  },
  {
    path: "/500",
    name: "error_500",
    component: () => import("../views/errores/500.vue")
  },
  {
    path: "/404",
    name: "error_404",
    component: () => import("../views/errores/404.vue")
  },
  {
    path: "/403",
    name: "error_403",
    component: () => import("../views/errores/403.vue")
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

//ROUTER LIFECYCLES
/*
Turns on and off the Superior NProgressBar 
*/
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});
// Se encarga de validar el token y redireccionar a capvital1 en caso de no tener un token valido.
router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) {
    router.push({ name: "error_404" });
  }
  if (to.query.tk) {
    store.dispatch(`initApp`, to.query.tk).then(() => {
      next();
    });
  }
  if (to.name == "login") {
    next();
  }
  if (
    !store.getters.isAuthenticated &&
    localStorage.getItem("accessToken") &&
    to.name != "login"
  ) {
    store.dispatch(`initApp`, localStorage.getItem("accessToken")).then(() => {
      next();
    });
  } else if (to.name == "login") {
    next();
  } else {
    window.location.href = config.client;
  }
});

export default router;
