import { render, staticRenderFns } from "./ContentPreview.vue?vue&type=template&id=15ce4f36&"
import script from "./ContentPreview.vue?vue&type=script&lang=js&"
export * from "./ContentPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports